<script lang="ts">
export default {
	name: 'FormOverlay',
	inheritAttrs: true,
	customOptions: {},
};
</script>

<script setup lang="ts">
defineProps({
	form: { type: Object, required: true },
	backLabel: { type: String, default: 'Cancel' },
	submitLabel: { type: String, default: 'Save' },
	backRoute: { type: String, required: false, default: '' },
	submitButtonClass: {
		type: String,
		default: 'px-6 py-2 text-white bg-green-500 rounded',
	},
	hasCustomSubmitButton: { type: Boolean, required: false, default: false },
});

defineEmits(['submitted']);
</script>

<template>
	<form @submit.prevent="$emit('submitted')">
		<slot />
		<div class="text-right mt-4">
			<slot name="additional_actions"></slot>
			<slot name="actions">
				<slot name="actions_before" />
				<a v-if="backRoute" :href="backRoute">
					<button type="button" class="px-6 py-2 mr-4 text-white bg-gray-500 rounded">
						{{ backLabel }}
					</button>
				</a>

				<button
					v-if="!hasCustomSubmitButton"
					type="submit"
					class="px-6 py-2 text-white bg-green-500 rounded"
					:class="[submitButtonClass, { 'opacity-25': form.processing }]"
					:disabled="form.processing"
				>
					{{ submitLabel }}
				</button>
				<slot name="actions_after" />
			</slot>
		</div>
	</form>
</template>

<!-- <style>
:root {
    /** @see https://github.com/twbs/bootstrap/issues/33757#issuecomment-826928169 */
    scroll-behavior: auto !important;
}
</style> -->
